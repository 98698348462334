
import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator'
import moment from 'moment'
import DatePickerDate from '@/models/DatePickerDate'
// @ts-ignore
import VueDatePicker from 'vue2-datepicker'
import IconAction from '@/components/IconAction/IconAction.vue'

@Component({
  components: {
    IconAction,
    'v-date-picker': VueDatePicker,
  },
})
export default class DatePicker extends Vue {
  @Prop({ default: true })
  public edit!: boolean

  @Prop({ default: '' })
  public label!: string

  @Prop({ default: 'input' })
  public id!: string

  @Prop({ default: '' })
  public rules!: [Object, String]

  @Prop({ default: '' })
  public vid!: string

  @Prop()
  public name!: string

  @Prop({ default: false })
  public range!: boolean

  @Prop({ default: false })
  public clearable!: boolean

  @Prop({ default: false })
  public vertical!: boolean

  @Prop({ default: false })
  public disabled!: boolean

  @Prop({ default: 'date' })
  public type!: string

  @Prop({ default: '2017-01-01' })
  public min_date!: string

  @Prop({ default: false })
  public no_shortcuts!: boolean

  @Prop({ default: false })
  public broadcastCalendar!: boolean

  @Prop({ default: () => moment().format('YYYY-MM-DD') })
  public value!: DatePickerDate | string

  @Prop({ default: 'default' })
  public shortcut_mode!: string

  @Prop({ default: false })
  public empty_value!: boolean

  @Prop({ default: () => new Date() })
  public defaultValue!: Date

  @Prop({ default: '' })
  public placeholder!: string

  private formatter = moment()

  public active_shortcut: string | null | number = null

  public is_shortcut: boolean = false

  public local_value: Date | Date[] | null = []

  private update: boolean = true

  public showTimePanel: boolean = false

  public secrect: string = ''

  public get defaultFormat() {
    if (this.range && this.type === 'period') {
      return 'MM/DD/YYYY'
    }
    if (this.type === 'date') {
      return 'MM/DD/YYYY'
    }
    if (this.type === 'month') {
      return 'MM/YYYY'
    }
    if (this.type === 'time') {
      return 'hh:mm:ss A'
    }
    if (this.type === 'year') {
      return 'YYYY'
    }

    return 'MM/DD/YYYY hh:mm:ss A'
  }

  public get lang() {
    if (this.broadcastCalendar !== false) {
      return {
        formatLocale: {
          firstDayOfWeek: 1,
        },
      }
    }

    return {}
  }

  public get uuid(): string {
    return `${this.id}-${this.secrect}`
  }

  public get format() {
    if (this.type === 'date') {
      return 'YYYY-MM-DD'
    }
    if (this.type === 'month') {
      return 'YYYY-MM'
    }
    if (this.type === 'time') {
      return 'HH:mm:ss'
    }
    if (this.type === 'year') {
      return 'YYYY'
    }
    return 'YYYY-MM-DD HH:mm:ss'
  }

  public mounted() {
    this.secrect = String(Math.floor(Math.random() * Math.floor(999)))
  }

  public notBeforeMinDate(date: any) {
    return !moment(date).isAfter(moment(this.min_date).subtract(1, 'day'))
  }

  public toggleTimePanel() {
    this.showTimePanel = !this.showTimePanel
  }

  public handleClose() {
    this.showTimePanel = false
  }

  @Watch('local_value')
  public onChangeValue() {
    if (!this.is_shortcut) {
      this.active_shortcut = null
    }
    this.update = false
    if (Array.isArray(this.local_value)) {
      this.$emit(
        'input',
        new DatePickerDate(
          moment(this.local_value[0]).format('YYYY-MM-DD HH:mm:ss'),
          moment(this.local_value[1]).format('YYYY-MM-DD HH:mm:ss'),
          this.active_shortcut,
          'YYYY-MM-DD HH:mm:ss',
        ),
      )
    } else {
      this.$emit('input', this.local_value ? moment(this.local_value).format(this.format) : '')
    }
    this.is_shortcut = false
  }

  @Watch('value')
  public onChangeProp(value: string | DatePickerDate, old: string | DatePickerDate) {
    if (
      value instanceof DatePickerDate
      && ((old instanceof DatePickerDate && value.hash !== old.hash) || !old)
    ) {
      if (value.shortcut) {
        this.active_shortcut = value.shortcut
        this.is_shortcut = true
      }
      this.local_value = [value.start_date, value.end_date]
    } else if (typeof value === 'string' && value !== old) {
      if (!value) {
        this.local_value = null
        return
      }
      this.local_value = moment(value, this.format).toDate()
    }
  }

  public created() {
    if (this.update) {
      if (this.value instanceof DatePickerDate) {
        if (this.value.shortcut) {
          this.active_shortcut = this.value.shortcut
          this.is_shortcut = true
        }
        this.local_value = [this.value.start_date, this.value.end_date]
      } else {
        this.local_value = this.value
          ? moment(this.value, this.format).toDate()
          : this.clearable !== false || this.empty_value !== false
            ? null
            : new Date()
      }
    }
  }

  @Prop({ default: null })
  public shortcuts!: [string, any] | null

  public clear() {
    // @ts-ignore
    this.$refs[`date_picker_${this.$attrs.id || this.uuid}`].clear()
  }

  public get shortcut_options() {
    if (!this.range || this.shortcut_mode == 'none') return []

    if (this.shortcut_mode == 'future') {
      return [
        {
          text: 'This Month',
          onClick: () => {
            this.is_shortcut = true
            this.active_shortcut = 'month'
            return [moment().add(1, 'hours').toDate(), moment().endOf('month').toDate()]
          },
        },
        {
          text: 'Next Month',
          onClick: () => {
            this.is_shortcut = true
            this.active_shortcut = '+month'
            return [
              moment().add(1, 'months').startOf('month').toDate(),
              moment().add(1, 'months').endOf('month').toDate(),
            ]
          },
        },
        {
          text: 'This year',
          onClick: () => {
            this.is_shortcut = true
            this.active_shortcut = 'year'
            return [
              moment().add(1, 'hours').toDate(),
              moment().add(1, 'months').endOf('year').toDate(),
            ]
          },
        },
      ]
    }

    return [
      {
        text: 'Today',
        onClick: () => {
          this.is_shortcut = true
          this.active_shortcut = 'today'
          return [moment().startOf('day').toDate(), moment().endOf('day').toDate()]
        },
      },
      {
        text: 'Yesterday',
        onClick: () => {
          this.is_shortcut = true
          this.active_shortcut = '-day'
          return [
            moment().subtract(1, 'days').startOf('day').toDate(),
            moment().subtract(1, 'days').endOf('day').toDate(),
          ]
        },
      },
      {
        text: 'This Week',
        onClick: () => {
          this.is_shortcut = true
          this.active_shortcut = 'week'
          return [
            moment().startOf('week').add(1, 'day').toDate(),
            moment().endOf('week').add(1, 'day').toDate(),
          ]
        },
      },
      {
        text: 'Last Week',
        onClick: () => {
          this.is_shortcut = true
          this.active_shortcut = '-week'
          return [
            moment().subtract(1, 'weeks').startOf('week').add(1, 'day')
              .toDate(),
            moment().subtract(1, 'weeks').endOf('week').add(1, 'day')
              .toDate(),
          ]
        },
      },
      {
        text: 'Last 7 days',
        onClick: () => {
          this.is_shortcut = true
          this.active_shortcut = '-7'
          return [
            moment().subtract(7, 'days').startOf('day').toDate(),
            moment().subtract(1, 'days').endOf('day').toDate(),
          ]
        },
      },
      {
        text: 'Last 30 days',
        onClick: () => {
          this.is_shortcut = true
          this.active_shortcut = '-30'
          return [
            moment().subtract(30, 'days').startOf('day').toDate(),
            moment().subtract(1, 'days').endOf('day').toDate(),
          ]
        },
      },
      {
        text: 'This Month',
        onClick: () => {
          this.is_shortcut = true
          this.active_shortcut = 'month'
          return [moment().startOf('month').toDate(), moment().endOf('month').toDate()]
        },
      },
      {
        text: 'Last Month',
        onClick: () => {
          this.is_shortcut = true
          this.active_shortcut = '-month'
          return [
            moment().subtract(1, 'months').startOf('month').toDate(),
            moment().subtract(1, 'months').endOf('month').toDate(),
          ]
        },
      },
      {
        text: 'This Year',
        onClick: () => {
          this.is_shortcut = true
          this.active_shortcut = 'year'
          return [moment().startOf('year').toDate(), moment().endOf('year').toDate()]
        },
      },
      {
        text: 'Last Year',
        onClick: () => {
          this.is_shortcut = true
          this.active_shortcut = '-year'
          return [
            moment().subtract(1, 'years').startOf('year').toDate(),
            moment().subtract(1, 'years').endOf('year').toDate(),
          ]
        },
      },
    ]
  }
}
